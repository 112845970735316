<template>
<div>

	<hero2 titre="Fractionnez vos biens immobiliers"
				 text="Fraction : la solution simple, sécure et efficace pour fractionner vos biens immobiliers avec la blockchain Stellar  "
				 img="https://media.berlcoin.fr/iconpuzzlehouse.png"
				 link_label1=""
				 link_label2=""
				 link1=""
				 link2=""
				 bt1="Tokenisation"
				 bt2="Application"
				 bt3="Market Place"
				 bt4="DéFi "
				 bx1="Nous transformons un actif immobilier en NFT. Nous créons des tokens répliquant la valeur de cet NFT et nous les intégrons à la blockchain Stellar"
				 bx2="Nous fournissons des wallets et des applications simples et pratiques pour la gestion de vos tokens et des relations avec vos partenaires."
				 bx3="Nous intégrons une place de marché dans nos apps pour transférer, vendre ou acheter vos tokens à moindres frais et en toute sécurité. "
				 bx4="Nous personnalisons des smart-contrats pour exploiter le formidable potentiel qu'offre la finance décentralisée dans l'immobilier."
				 bi1="https://media.berlcoin.fr/5372580.jpg"
				 bi2="https://media.berlcoin.fr/8.svg"
				 bi3="https://media.berlcoin.fr/3516854.jpg"
				 bi4="https://media.berlcoin.fr/11121-2.svg" />

	<organigram1 class="mt-12"
							 titre="Les avantages de la tokenisation des actifs immobiliers"
							 top1="Passez au numérique"
							 top1t="Le processus d'investissement décentralisé et numérique réduit les coûts et vous rend attrayant pour les investisseurs."
							 top2="Fractionnez la propriété"
							 top2t="La tokenisation de l'immobilier permet à une propriété d'être détenue par des milliers d'investisseurs."
							 top3="Attirez les petits investisseurs"
							 top3t="Le fractionnement réduit considérablement le seuil d'investissement."
							 bottom1="Achetez, vendez"
							 bottom1t="Les tokens peuvent être facilement échangés sur une place de marché décentralisée ce qui réduit les risques et les coûts pour les investisseurs."
							 bottom2="Le monde est à vous"
							 bottom2t="Dépassez les limites physiques ! Désormais, les investisseurs de n'importe quel coin du globe peuvent acheter une fraction de votre bien immobilier."
							 bottom3="Développez-vous"
							 bottom3t="Vous pouvez désormais toucher plus d'investisseurs, plus facilement et à moindre coût pour croître plus rapidement."
							 center="Avec ces avantages combinés vous devenez plus attrayant pour les investisseurs, mobilisez plus de capitaux à de meilleures conditions et développez votre patrimoine immobilier plus rapidement." />


	<testimonial4 text="“La tokenisation est une technologie révolutionnaire qui vous permet de fractionner un bien immobilier.
    Un NFT représente l'intégralité des droits de propriété.  <br/>Un token représente une fraction de la propriété de l'immobilier,
    le rendant plus accessible aux petits investisseurs.”"
								name=""
								subname="" />


	<feature2 accroche="L'immobilier 3.0"
						titre="L'investissement immobilier réinventé "
						lead="C'est à la base une idée simple : investir à plusieurs dans l'immobilier avec un smartphone et une carte bancaire."
						text="Pour mettre en pratique cette idée, nous avons recours au meilleur de la technologie actuelle : <b>la finance décentralisée</b>.
                <br/><br/>Cela permet de transformer un bien immobilier en actif financier, puis de créer des tokens garantis par cet actif qui sont échangeables sur une place de marché,
                via un smartphone.
                <br/><br/>C'est un processus technique et financier complexe pour que cela devienne très simple pour vous.
                <br/><br/>"
						t1="Immeuble"
						p1="Nous achetons un bien immobilier physique : un appartement, une maison, une place de parking..."
						k1=""
						l1=""
						img1="https://media.berlcoin.fr/f1.jpg"
						t2="Actif Financier"
						p2="Grâce à la finance décentralisée, nous transformons cet immeuble en actif financier."
						k2=""
						l2=""
						img2="https://media.berlcoin.fr/f2.jpg"
						t3="Tokenisation "
						p3="Nous émettons des tokens <b>Berlcoin</b> sur la blockchain dont la valeur est garantie par cet actif."
						k3=""
						l3=""
						img3="https://media.berlcoin.fr/f3.jpg"
						t4="Place de marché"
						p4="Vous achetez et vous vendez vos Berlcoins sur une place de marché avec votre smartphone."
						k4=""
						l4=""
						img4="https://media.berlcoin.fr/f4.jpg"
						color="soft-green" />


	<feature7 accroche=""
						titre="Solution complète, personnalisée et privatisée"
						lead="Fraction n'est pas une plateforme d'investissement participatif ou une bourse de valeurs tokenisées.
    Il s'agit d'une infrastructure en marque blanche   destinée uniquement à votre entreprise "
						:elements="elements" />

	<process1 image="https://media.berlcoin.fr/device.png"
						accroche="Les outils de l'immobilier 3.0"
						titre="Wallet & Marketplaces"
						lead="Nous mettons à votre disposition un outil spécialisé pour vos investisseurs."
						text="Cette application personnalisée spécifiquement pour vos tokens est disponible via le site web ou par une app et permet à vos investisseurs de réaliser et suivre facilement leurs transactions:"
						:elements=el_process1
						link1="Investir"
						link_label1=""
						link_label2="" />

	<feature1 accroche=""
						titre=""
						lead=""
						text=""
						t1="Immeuble"
						p1="Vous achetez un bien immobilier physique : un appartement, une maison, une place de parking..."
						k1=""
						l1=""
						img1="uil-home"
						t2="Actif financier"
						p2="Grâce à la finance décentralisée, vous transformez cet immeuble en actif financier."
						k2=""
						l2=""
						img2="uil-calculator-alt"
						t3="Tokenisation "
						p3="Vous émettez des tokens  sur la blockchain dont la valeur est garantie par cet actif."
						k3=""
						l3=""
						img3="uil-circle-layer      "
						t4="Place de marché"
						p4="Vous achetez et vous vendez ces tokens sur une place de marché avec un smartphone"
						k4=""
						l4=""
						img4="uil-mobile-android"
						color="soft-green" />

	<process3 t1="achat de tokens"
						tx1="Achat de tokens sur la place de marché à cours défini ou à cours libre selon l'offre et la demande."
						t2="valorisation"
						tx2="La valeur des tokens augmente en fonction des revenus et des plus-values."
						t3="revente"
						tx3="Revente des tokens pour encaisser les bénéfices sans délai et en quelques clics."
						titre="L'immobilier fait sa révolution grâce à la finance décentralisée"
						soustitre="Comment cela fonctionne-t'il pour les investisseurs ?"
						lead="Vous achetez et vendez des berlcoins dont la valeur est garantie par des biens immobiliers sans aucune contrainte."
						soustitre2="Les avantages de la finance décentralisée"
						lead2='Nous transformons des actifs immobiliers en actifs financiers'
						it1="simplicité & garantie"
						id1="Bénéficiez de la stabilité et de la garantie de l'immobilier pour réaliser des investissements aussi simplement qu’en utilisant un compte d’épargne."
						it2="dynamisme & souplesse"
						id2="Bénéficiez du dynamisme du marché immobilier pour réaliser des plus-values importantes sans les contraintes habituelles d'accès à ce marché."
						it3="blockchain & sécurité"
						id3="Bénéficiez des technologies de la blockchain pour sécuriser et acheter ou revendre vos actifs sur n'importe quelle place de marché." />

	<process5 class="mt-6"
						titre="Concrètement, que faisons-nous ?"
						lead='Nous tokenisons un bien immobilier: nous créons un NFT, des tokens et une place de marché'
						it1="Création d'un NFT pour chaque bien immobilier."
						id1="Nous créons un type particulier de token (un NFT) qui est la représentation numérique des droits de propriété spécifiques à chaque bien immobilier."
						it2="Création de tokens adossés à chaque NFT."
						id2="Le nombre de tokens créés dépend de la valeur du bien. Le portefeuille de garantie du token correspond à l’ensemble des biens convertis en NFT."
						it3="Distribution de tokens."
						id3="Chaque associé reçoit le nombre de tokens qui correspond à sa part des droits de propriété en fonction de ses investissements. "
						it4="Ouverture d'une place de marché."
						id4="Les associés qui disposent de tokens peuvent les vendre ou en acheter sur une place de marché décentralisée." />





	<price2 titre="Nos offres"
					lead="Des offres adaptées à vos projets d'immobilier tokenisé "
					text="Nous proposons des offres standardisées et nous avons des solutions spécifiquement adaptées à vos besoins à vous proposer, que ce soit pour un project spécifique ou pour une gestion multi-projets. "
					link_label1=""
					link1=""
					:offres="offres" />

	<feature6 accroche=""
						titre="Quels clients pouvez-vous atteindre avec un token immobilier ?"
						t1="Investisseurs crypto"
						p1="Les investisseurs en crypto adorent les actifs tokenisés. Les jetons de sécurité leur permettent de diversifier le portefeuille et d'obtenir plus de protection que les actifs cryptographiques conventionnels."
						k1=""
						l1=""
						img1="https://media.berlcoin.fr/crypto-investors.svg"
						t2="Investisseurs millénials"
						p2="Les investisseurs modernes de la génération Y recherchent des actifs alternatifs pour se protéger de la volatilité des marchés boursiers ou crypto tout en obtenant de meilleurs rendements."
						k2=""
						l2=""
						img2="https://media.berlcoin.fr/safety_1.svg"
						t3="SCI et HNWI"
						p3="L'immobilier tokenisé est également intéressant pour les gérants de SCI et les High Net Worth Individuals qui souhaitent diversifier leurs investissements, disposer de plus de liquidités et d'un accès à de grands actifs."
						k3=""
						l3=""
						img3="https://media.berlcoin.fr/insurance.svg" />


	<portfolio1 v-if="false"
							accroche="Exemple d'application"
							titre="La tokenisation d'actifs immobiliers adaptés à vos besoins"
							:elements="exemples" />


</div>
</template>

<script>

import hero2 from "@/components/hero2.vue";
import feature1 from "@/components/feature1.vue";
import feature2 from "@/components/feature2.vue";
import feature6 from "@/components/feature6.vue";
import testimonial4 from "@/components/testimonial4.vue";
import process5 from "@/components/process5.vue";
import testimonial2 from "@/components/testimonial2.vue";
import organigram1 from "@/components/organigram-1.vue";
import feature7 from "@/components/feature7.vue";
import portfolio1 from "@/components/portfolio-1.vue";
import process1 from "@/components/process1.vue";
import process3 from "@/components/process3.vue";
import price2 from "@/components/price2.vue";

export default {
  name: 'Home',
  components: {
    feature1,
    feature2,
    feature6,
    feature7,
    hero2,
    testimonial4,
    testimonial2,
    process1,
    process5,
    organigram1,
    portfolio1,process3,price2
  },
  props: {},
  data: () => ({


 exemples: [{

        image:"https://media.berlcoin.fr/22.svg",
   title:"",
   text: "",
   link: "",
   category:"Résidentiel locatif"
 },

 {
  image:"https://media.berlcoin.fr/3347.svg",
  title:"",
  text: "",
  link: "",
   category:"Déficit foncier"
 },
 {
  image:"https://media.berlcoin.fr/32321.svg",
  title:"",
  text: "",
  link: "",
   category:"Location en Meublé"
 },


 {
  image:"https://media.berlcoin.fr/5765699.svg",
  title:"",
  text: "",
  link: "",
   category:"Parking, box et garage"
 },
 {
    image:"https://media.berlcoin.fr/20943821.svg",
    title:"",
    text: "",
    link: "",
   category:"Viager"
 },


 {
  image:"https://media.berlcoin.fr/24.svg",
  title:"",
  text: "",
  link: "",
   category:"Terrain, forêt, vignobles"
 },


  {
    image:"https://media.berlcoin.fr/19198806.svg",

    title:"",
    text: "",
    link: "",
    category:"Location saisonnière"
  },
  {
    image:"https://media.berlcoin.fr/21.svg",
    title:"",
    text: "",
    link: "",
 category:"Immeuble complet"
      },
      {
    image:"https://media.berlcoin.fr/1035.svg",
    title:"",
    text: "",
    link: "",
     category:"Bureaux et murs d’entreprise"
   },
   {
      image:"https://media.berlcoin.fr/20945159.svg",
      title:"",
      text: "",
      link: "",
  category:"Achat-revente "
       },
       {
      image:"https://media.berlcoin.fr/1763.svg",
      title:"",
      text: "",
      link: "",
      category:"Colocation"
    },
    {
      image:"https://media.berlcoin.fr/949.svg",
      title:"",
      text: "",
      link: "",
   category:"International"
 },
      ]
,
    elements: [{
      image:"https://media.berlcoin.fr/settings_1.svg",
      title:"Configuration et utilisation faciles",
      text: "Pas besoin de connaissances complexes ou de personnel informatique supplémentaire.      Un gestionnaire dédié peut facilement exécuter des opérations sur le dashboard.",
      link: "",
    }, {
      image:"https://media.berlcoin.fr/icon-stand-alone.svg",
      title:"Intégrée à votre entreprise",
      text: " Le tableau de bord personnalisé se déploie dans le cadre de votre site Web d'entreprise et devient une passerelle pour vos actionnaires ou investisseurs.",
      link: "",
    },{
      image:"https://media.berlcoin.fr/icon-dashboard.svg",
      title:"1 tableau de bord, 2 interfaces",
      text: "Le dashboard est optimisé pour deux environnements uniques. L'un est pour la gestion d'entreprise, un autre est pour les investisseurs et les actionnaires.",
      link: "",
    },{
      image:"https://media.berlcoin.fr/icon-legal.svg",
      title:"Aucune complexité juridique",
      text: "Pas besoin d'autorisation des régulateurs pour gérer votre propre registre sur la blockchain si la gestion est assurée par les investisseurs dans le cadre d’une mise en commun de moyens.",
      link: "",
    },{
      image:"https://media.berlcoin.fr/gdpr_1.svg",
      title:"Les données sont à vous",
      text: "Fraction.re ne partage pas vos investisseurs avec d'autres et fournit une infrastructure prête à l'emploi pour stocker les données de vos investisseurs. Conforme au RGPD.",
      link: "",
    },{
      image:"https://media.berlcoin.fr/icon-security.svg",
      title:"La sécurité est la priorité",
      text: "Outre l'utilisation d'un registre immuable sur la blockchain, nous utilisons la meilleure pile technologique pour nous assurer que vos données sont sûres et sécurisées.",
      link: "",
    }, ],

el_process1: [
    {text:"Suivi des investissements"},
  {text:"Souscription de contrats à terme (staking)"},
  {text:"Accès direct aux places de marché"},
  {text:"Consultation de l'historique des transactions"},
	  {text:"Dépôt et retrait des Liquidity Pools"},
		{text:"Achat & vente"},
				{text:"Transfert"},
				{text:"Tiers de confiance"},
		{text:"Unités de compte"},
    {text:"Smart Contracts"}
],










offres: [
    {
      prix : "190",
      titre:"Bien immobilier",
      puces:[
        { icon:"check",couleur: "green",  text:"Frais d'installation : 2500 €" },
        { icon:"check",couleur: "green",    text:"1 bien immoblier " },
        { icon:"check",couleur: "green",    text:"Interface de gestion" },
        { icon:"check",couleur: "green",    text:"Wallet" },
        { icon:"check",couleur: "green",    text:"Place de marché" },
        { icon:"check",couleur: "green",    text:"Portefeuille de NFT" },
        { icon:"check",couleur: "green",    text:"Smart-Contracts" },
      ],
      liens:"",
      label:""
    },
    {
      prix : "490",
      titre:"Ensemble immobilier",
      puces:[
        { icon:"check",couleur: "green",  text:"Frais d'installation : 5000 €" },
        { icon:"check",couleur: "green",    text:"1 ensemble immoblier " },
        { icon:"check",couleur: "green",    text:"Interface de gestion" },
        { icon:"check",couleur: "green",    text:"Wallet" },
        { icon:"check",couleur: "green",    text:"Place de marché" },
        { icon:"check",couleur: "green",    text:"Portefeuille de NFT" },
          { icon:"check",couleur: "green",    text:"Smart-Contracts" },
    { icon:"check",couleur: "green",    text:"Unité de compte" },
      ]
      ,
      liens:"",
      label:""
    },
  ]

  }),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed: {},
  methods: {},
  watch: {}
  }
  </script>

  <style scoped>
  </style>
