<template>
<nav class="navbar navbar-expand-lg center-nav navbar-dark navbar-bg-dark">
  <div class="container flex-lg-row flex-nowrap align-items-center">
    <div class="navbar-brand w-100">
      <a :href="link" class="h1 text-white">
          <b v-html="title"></b>
        </a>
        <a :href="link" class="h3 text-white"  v-html="title2">
        </a>
    </div>
    <div class="navbar-collapse offcanvas-nav">
      <div class="offcanvas-header d-lg-none d-xl-none">
        <a href="/">
          <img src="@/assets/img/logo-light.png" srcset="@/assets/img/logo-light@2x.png 2x" alt="" /></a>
        <button type="button" class="btn-close btn-close-white offcanvas-close offcanvas-nav-close" aria-label="Close"></button>
      </div>
      <ul class="navbar-nav">
      <li  v-for="(item, index) in pages" :key="'nn'+index" class="nav-item"><a class="nav-link" :href="item.link">{{item.page}}</a></li>

      </ul>
      <!-- /.navbar-nav -->
    </div>
    <!-- /.navbar-collapse -->
    <div class="navbar-other w-100 d-flex ms-auto">
      <ul class="navbar-nav flex-row align-items-center ms-auto " data-sm-skip="true">
        <li class="nav-item dropdown language-select text-uppercase d-lg-none" >
          <a class="nav-link dropdown-item  " href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="uil uil-align-justify"></i></a>
          <ul class="dropdown-menu">
            <li v-for="(item, index) in pages" :key="'dm'+index" class="nav-item">
           <a class="dropdown-item" :href="item.link">{{item.page}}</a>
         </li>

          </ul>
        </li>
        <li v-if="false" class="nav-item dropdown language-select text-uppercase d-none d-md-block">
          <a class="nav-link dropdown-item dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">En</a>
          <ul   class="dropdown-menu">
              <li v-for="(item, index) in langs" :key="'lg'+index"  class="nav-item"><a class="dropdown-item" :href="item.link">{{item.lang}}</a></li>

          </ul>
        </li>
        <li v-if="false" class="nav-item  ">
          <a href="property" class="btn btn-sm btn-success  ">Investir</a>
        </li>


      </ul>
    </div>
  </div>
</nav>
</template>


<script>
export default {
  name: 'nav1',
  components: {},
  props: {
    title: String,
    title2: String,
    link: {
      type: String,
      default: "/"
    },
    pages:[],
      langs:[],

  },
  data: () => ({}),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style scoped>
</style>
