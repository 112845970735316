<template>
<div id="app">
	<nav1 title="Fraction.re"
				v-if="false"
				title2=""
				:pages="pages"
				:langs="langs" />
	<router-view />
	<footer2 origine="fraction.re"
					 text="
Fraction.re est une Société par actions simplifiée à capital variable de 250 000 euros minimum enregistrée au Registre du Commerce et des Sociétés de Paris sous le numéro 908 680 002
<br/>Fraction.re (re pour Real Estate) a été fondée dans le but d'utiliser la technologie de la blockchain afin de révolutionner les technologies financières de l'immobilier pour plus d'efficacité, plus d'accessibilité et plus de transparence.

"
					 title="Fraction.re"
					 title2=""
					 :pages="pages"
					 :langs="langs"
					 address1="56 rue Monge"
					 address2="75005 Paris France"
					 copyright="2021 Fraction Immo SAS. "
					 mail="info@fraction.re" />
</div>
</template>



<script>
import footer2 from "@/components/footer2.vue";
import nav1 from "@/components/nav1.vue";



export default {
	name: 'app',
	components: {
		nav1,
		footer2
	},
	props: {},
	data: () => ({

		pages: [{
				page: "Fraction.immo",
				link: "https://fraction.immo"
			}, {
				page: "Berlcoin",
				link: "https://berlcoin.berlin"
			},
			{
				page: "Wallet",
				link: "https://wallet.fraction.re"
			}
		],
		langs: [{
			lang: "FR",
			link: "fr"
		}, {
			lang: "ES",
			link: "es"
		}, {
			lang: "EN",
			link: "en"
		}, {
			lang: "DE",
			link: "de"
		}]

	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>


<style>
@import 'assets/css/plugins.css';
@import 'assets/css/style.css';
@import 'assets/css/colors/orange.css';
@import 'assets/css/fonts/thicccboi.css';



html,
body {
	font-family: 'Montserrat', sans-serif;

}

#app {
	font-family: Montserrat, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
}

#nav {
	padding: 30px;
}

#nav a {
	font-weight: bold;
	color: #2c3e50;
}

#nav a.router-link-exact-active {
	color: #42b983;
}
</style>
