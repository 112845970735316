<template>


  <section class="wrapper bg-light">
    <div class="container py-14 py-md-16">
      <div class="row position-relative">
        <figure class="rounded position-absolute d-none d-lg-block"
        style="top: 50%; right:0; width: 45%; height: auto; transform: translateY(-50%); z-index:2">
        <img style="background-color:silver; padding:1px;box-shadow: 3px 3px 3px silver;" src="@/assets/media/26824.svg"  alt=""></figure>
        <div class="col-lg-9 text-center">
          <div class="card bg-soft-blue">
            <div class="card-body p-md-10 py-xxl-16">
              <div class="row gx-0">
                <div class="col-lg-8 ps-xl-10">
                  <span class="ratings five mb-3"></span>
                  <blockquote class="border-0 fs-lg mb-0">
                    <p v-html="text"></p>
                    <div class="blockquote-details justify-content-center text-center">
                      <div class="info p-0">
                        <h5 class="mb-1">{{name}}</h5>
                        <p class="mb-0">{{subname}}</p>
                      </div>
                    </div>
                  </blockquote>
                </div>
                <!-- /column -->
              </div>
              <!-- /.row -->
            </div>
            <!--/.card-body -->
          </div>
          <!--/.card -->
        </div>
        <!-- /column -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container -->
  </section>
  <!-- /section -->


</template>


<script>
const eurocurrency = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2
});


export default {
  name: 'testimonial4',
  components: {},
  props: {
    text:String,
    name:String,
    subname:String,
  },
  data: () => ({}),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style scoped>
</style>
